<template>
  <div>
    <UtilTabs :tabs="compTabs" :value="$route.path" @input="path => $router.push(path).catch(() => {})" />

    <div class="mt-8">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import UtilTabs from '@/components/utils/UtilTabs'

export default {
  name: 'Haulage',
  components: {
    UtilTabs
  },
  computed: {
    compTabs() {
      return [
        {
          label: 'Overview',
          value: '/haulage'
        },
        {
          label: 'Hauls',
          value: '/haulage/hauls'
        },
        {
          label: 'Estimates',
          value: '/haulage/estimates'
        }
      ]
    }
  }
}
</script>
