<template>
  <div class="flex flex-col">
    <div class="-my-2 sm:-mx-6 lg:-mx-8" :class="{ 'overflow-x-auto': allowSideScroll }">
      <div class="py-2 align-middle min-w-full sm:px-6 lg:px-8">
        <div class="shadow border-b border-gray-400 sm:rounded-lg" :class="{ 'overflow-hidden': allowSideScroll }">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  v-for="(col, index) in columns"
                  :key="index"
                  scope="col"
                  class="px-6 py-3 bg-gray-200 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                >
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rowData, rowDataIndex) in rows"
                :key="rowDataIndex"
                class="bg-white hover:bg-yellow-100 even:bg-gray-100 even:hover:bg-yellow-100"
              >
                <td
                  v-for="(rowItem, rowItemIndex) in rowData"
                  :key="rowItemIndex"
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium"
                >
                  {{ rowItem }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UtilTable',
  props: {
    columns: Array,
    rows: Array,
    allowSideScroll: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
