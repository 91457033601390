<template>
  <div>
    <FormDateRange v-model="dateRange" minDate="2020-06-01" />

    <h4 class="font-medium leading-none text-lg mt-4">Real-Time Leads Stats</h4>

    <div class="flex items-center mt-4">
      <div class="bg-white shadow rounded text-center px-8 py-2 mr-4">
        <p class="uppercase">New Haulage</p>

        <p class="font-medium text-6xl leading-tight mt-2">4</p>
      </div>

      <div class="bg-white shadow rounded text-center px-8 py-2 mr-4">
        <p class="uppercase">In Progress Haulage</p>

        <p class="font-medium text-6xl leading-tight mt-2">15</p>
      </div>

      <div class="bg-white shadow rounded text-center px-8 py-2 mr-4">
        <p class="uppercase">Total Overall</p>

        <p class="font-medium text-6xl leading-tight mt-2">850</p>
      </div>
    </div>

    <div class="mt-4 bg-white rounded shadow px-4 py-4">
      <div class="flex flex-wrap">
        <div class="w-6/12 pr-2">
          <h4 class="font-medium text-center leading-none text-lg mb-4">Quotes</h4>

          <div class="relative">
            <canvas ref="acceptedRejectedChart"></canvas>
          </div>
        </div>

        <div class="w-6/12 pl-2">
          <h4 class="font-medium text-center leading-none text-lg mb-4">Rejected Reasons</h4>

          <div class="relative">
            <canvas ref="rejectedPieChart"></canvas>
          </div>
        </div>
      </div>

      <div class="mt-8">
        <UtilTable :columns="statsData.columns" :rows="statsData.rows" />
      </div>
    </div>

    <div class="mt-4 bg-white rounded shadow flex px-4 py-4">
      <div class="w-6/12 pr-2">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Revenue</h4>

        <div class="relative">
          <canvas ref="revenueMonthChart"></canvas>
        </div>
      </div>

      <div class="w-6/12 pl-2">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Revenue YTD</h4>

        <div class="relative">
          <canvas ref="revenueYearChart"></canvas>
        </div>
      </div>
    </div>

    <div class="mt-4 bg-white rounded shadow flex px-4 py-4">
      <div class="w-6/12 pr-2">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Profit</h4>

        <div class="relative">
          <canvas ref="profitMonthChart"></canvas>
        </div>
      </div>

      <div class="w-6/12 pl-2">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Profit YTD</h4>

        <div class="relative">
          <canvas ref="profitYearChart"></canvas>
        </div>
      </div>
    </div>

    <div class="flex gap-4">
      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Average Margin</h4>

        <div class="relative">
          <canvas ref="averageMarginChart"></canvas>
        </div>
      </div>

      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Acquisition</h4>

        <div class="relative">
          <canvas ref="acquisitionPieChart"></canvas>
        </div>
      </div>
    </div>

    <div class="flex gap-4">
      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Haulage Urgency</h4>

        <div class="relative">
          <canvas ref="urgencyChart"></canvas>
        </div>
      </div>

      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Regional Accepted Quotes</h4>

        <div class="relative">
          <UtilTable :columns="regionsData.columns" :rows="regionsData.rows" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
import FormDateRange from '@/components/forms/elements/FormDateRange'
import UtilTable from '@/components/utils/UtilTable.vue'

export default {
  name: 'HaulageOverview',
  components: {
    FormDateRange,
    UtilTable
  },
  data() {
    return {
      dateRange: {
        startDate: this.$date().subtract(3, 'month'),
        endDate: this.$date()
      },
      statsData: {
        columns: [],
        rows: []
      },
      regionsData: {
        columns: [],
        rows: []
      },
      config: {
        colors: {
          red: {
            backgroundColor: 'rgba(255, 0, 0, 0.6)',
            borderColor: 'rgba(255, 0, 0, 1)'
          },
          green: {
            backgroundColor: 'rgba(0, 255, 0, 0.6)',
            borderColor: 'rgba(0, 255, 0, 1)'
          },
          blue: {
            backgroundColor: 'rgba(0, 0, 255, 0.6)',
            borderColor: 'rgba(0, 0, 255, 1)'
          },
          yellow: {
            backgroundColor: 'rgba(255, 255, 0, 0.6)',
            borderColor: 'rgba(255, 255, 0, 1)'
          }
        }
      }
    }
  },
  mounted() {
    this.setup()
  },
  methods: {
    async setup() {
      this.loadAcceptedRejectedTable()
      this.loadAcceptedRejectedChart()
      this.loadUrgencyChart()
      this.loadRevenueMonthChart()
      this.loadRevenueYearChart()
      this.loadProfitMonthChart()
      this.loadProfitYearChart()
      this.loadRejectedPieChart()
      this.loadRegionsTable()
      this.loadMarginChart()
      this.loadAcquisitionPieChart()
    },

    loadAcceptedRejectedTable() {
      let data = [
        {
          date: '2020-01-01T00:00:00+00:00',
          acceptedTotal: 150,
          acceptedPercentage: 20.0,
          rejectedTotal: 600,
          rejectedPercentage: 80.0,
          totalQuotes: 750
        },
        {
          date: '2020-02-01T00:00:00+00:00',
          acceptedTotal: 150,
          acceptedPercentage: 20.0,
          rejectedTotal: 600,
          rejectedPercentage: 80.0,
          totalQuotes: 750
        },
        {
          date: '2020-03-01T00:00:00+00:00',
          acceptedTotal: 150,
          acceptedPercentage: 20.0,
          rejectedTotal: 600,
          rejectedPercentage: 80.0,
          totalQuotes: 750
        },
        {
          date: '2020-04-01T00:00:00+00:00',
          acceptedTotal: 150,
          acceptedPercentage: 20.0,
          rejectedTotal: 600,
          rejectedPercentage: 80.0,
          totalQuotes: 750
        },
        {
          date: '2020-05-01T00:00:00+00:00',
          acceptedTotal: 150,
          acceptedPercentage: 20.0,
          rejectedTotal: 600,
          rejectedPercentage: 80.0,
          totalQuotes: 750
        },
        {
          date: '2020-06-01T00:00:00+00:00',
          acceptedTotal: 150,
          acceptedPercentage: 20.0,
          rejectedTotal: 600,
          rejectedPercentage: 80.0,
          totalQuotes: 750
        },
        {
          date: null,
          acceptedTotal: 150,
          acceptedPercentage: 20.0,
          rejectedTotal: 600,
          rejectedPercentage: 80.0,
          totalQuotes: 750
        }
      ]

      this.statsData.columns = ['', 'Accepted', 'Percentage', 'Rejected', 'Percentage', 'Total']

      data.forEach(d => {
        this.statsData.rows.push([
          d.date ? this.$date(d.date).format('MMM YY') : 'Total',
          this.$numberFormat(d.acceptedTotal),
          `${d.acceptedPercentage}%`,
          this.$numberFormat(d.rejectedTotal),
          `${d.rejectedPercentage}%`,
          this.$numberFormat(d.totalQuotes)
        ])
      })
    },

    loadAcceptedRejectedChart() {
      let data = [
        {
          date: '2020-01-01T00:00:00+00:00',
          totalAccepted: 80,
          totalRejected: 150
        },
        {
          date: '2020-02-01T00:00:00+00:00',
          totalAccepted: 90,
          totalRejected: 140
        },
        {
          date: '2020-03-01T00:00:00+00:00',
          totalAccepted: 170,
          totalRejected: 130
        },
        {
          date: '2020-04-01T00:00:00+00:00',
          totalAccepted: 110,
          totalRejected: 80
        },
        {
          date: '2020-05-01T00:00:00+00:00',
          totalAccepted: 120,
          totalRejected: 110
        },
        {
          date: '2020-06-01T00:00:00+00:00',
          totalAccepted: 130,
          totalRejected: 100
        },
        {
          date: '2020-07-01T00:00:00+00:00',
          totalAccepted: 140,
          totalRejected: 90
        }
      ]

      let labels = data.map(d => this.$date(d.date).format('MMM YY'))

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          tooltips: {
            mode: 'index',
            intersect: false
          },
          scales: {
            xAxes: [
              {
                stacked: true
              }
            ],
            yAxes: [
              {
                stacked: true
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Accepted Quotes',
              ...this.config.colors.green,
              data: data.map(d => d.totalAccepted),
              fill: false
            },
            {
              label: 'Rejected Quotes',
              ...this.config.colors.red,
              data: data.map(d => d.totalRejected),
              fill: false
            }
          ]
        }
      }

      new Chart(this.$refs.acceptedRejectedChart.getContext('2d'), chartData)
    },

    loadUrgencyChart() {
      let data = [
        {
          label: '1 day',
          total: 50
        },
        {
          label: '2 days',
          total: 100
        },
        {
          label: '3 days',
          total: 800
        },
        {
          label: '4 days',
          total: 600
        },
        {
          label: '5 days',
          total: 650
        },
        {
          label: '6-14 days',
          total: 30
        },
        {
          label: '14 days+',
          total: 10
        }
      ]

      let labels = data.map(d => d.label)

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Urgency',
              backgroundColor: 'rgba(255, 0, 0, 0.6)',
              borderColor: 'rgba(255, 0, 0, 0.6)',
              data: data.map(d => d.total)
            }
          ]
        }
      }

      new Chart(this.$refs.urgencyChart.getContext('2d'), chartData)
    },

    loadRevenueMonthChart() {
      let data = [
        {
          date: '2020-01-01T00:00:00+00:00',
          revenue: 10000,
          target: 25000
        },
        {
          date: '2020-02-01T00:00:00+00:00',
          revenue: 18000,
          target: 25000
        },
        {
          date: '2020-03-01T00:00:00+00:00',
          revenue: 23000,
          target: 25000
        },
        {
          date: '2020-04-01T00:00:00+00:00',
          revenue: 30000,
          target: 25000
        },
        {
          date: '2020-05-01T00:00:00+00:00',
          revenue: 50000,
          target: 25000
        },
        {
          date: '2020-06-01T00:00:00+00:00',
          revenue: 60000,
          target: 25000
        },
        {
          date: '2020-07-01T00:00:00+00:00',
          revenue: 70000,
          target: 25000
        }
      ]

      let labels = data.map(d => this.$date(d.date).format('MMM YY'))

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Revenue',
              ...this.config.colors.blue,
              data: data.map(d => d.revenue)
            },
            {
              label: 'Target',
              ...this.config.colors.red,
              data: data.map(d => d.target)
            }
          ]
        }
      }

      new Chart(this.$refs.revenueMonthChart.getContext('2d'), chartData)
    },

    loadRevenueYearChart() {
      let data = [
        {
          total: 130000
        },
        {
          total: 120000
        }
      ]

      let labels = ['Revenue', 'Target']

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Revenue',
              backgroundColor: [this.config.colors.blue.backgroundColor, this.config.colors.red.backgroundColor],
              borderColor: [this.config.colors.blue.borderColor, this.config.colors.red.borderColor],
              data: data.map(d => d.total)
            }
          ]
        }
      }

      new Chart(this.$refs.revenueYearChart.getContext('2d'), chartData)
    },

    loadProfitMonthChart() {
      let data = [
        {
          date: '2020-01-01T00:00:00+00:00',
          profit: 10000,
          target: 25000
        },
        {
          date: '2020-02-01T00:00:00+00:00',
          profit: 18000,
          target: 25000
        },
        {
          date: '2020-03-01T00:00:00+00:00',
          profit: 23000,
          target: 25000
        },
        {
          date: '2020-04-01T00:00:00+00:00',
          profit: 30000,
          target: 25000
        },
        {
          date: '2020-05-01T00:00:00+00:00',
          profit: 50000,
          target: 25000
        },
        {
          date: '2020-06-01T00:00:00+00:00',
          profit: 60000,
          target: 25000
        },
        {
          date: '2020-07-01T00:00:00+00:00',
          profit: 70000,
          target: 25000
        }
      ]

      let labels = data.map(d => this.$date(d.date).format('MMM YY'))

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Profit',
              ...this.config.colors.blue,
              data: data.map(d => d.profit)
            },
            {
              label: 'Target',
              ...this.config.colors.red,
              data: data.map(d => d.target)
            }
          ]
        }
      }

      new Chart(this.$refs.profitMonthChart.getContext('2d'), chartData)
    },

    loadProfitYearChart() {
      let data = [
        {
          total: 130000
        },
        {
          total: 120000
        }
      ]

      let labels = ['Profit', 'Target']

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Profit',
              backgroundColor: [this.config.colors.blue.backgroundColor, this.config.colors.red.backgroundColor],
              borderColor: [this.config.colors.blue.borderColor, this.config.colors.red.borderColor],
              data: data.map(d => d.total)
            }
          ]
        }
      }

      new Chart(this.$refs.profitYearChart.getContext('2d'), chartData)
    },

    loadRejectedPieChart() {
      let data = [
        {
          rejectionReason: 'Unspecified',
          total: 1300
        },
        {
          rejectionReason: 'Too Expensive',
          total: 540
        },
        {
          rejectionReason: 'Price Checking',
          total: 231
        },
        {
          rejectionReason: 'Doing Haulage Personally',
          total: 10
        }
      ]

      let labels = data.map(d => d.rejectionReason)

      let chartData = {
        type: 'pie',
        options: {
          responsive: true
        },
        data: {
          labels: labels,
          datasets: [
            {
              backgroundColor: [
                this.config.colors.blue.backgroundColor,
                this.config.colors.red.backgroundColor,
                this.config.colors.green.backgroundColor,
                this.config.colors.yellow.backgroundColor
              ],
              data: data.map(d => d.total)
            }
          ]
        }
      }

      new Chart(this.$refs.rejectedPieChart.getContext('2d'), chartData)
    },

    loadRegionsTable() {
      let data = [
        {
          region: 'South West',
          percentageOfAcceptedQuotes: 40
        },
        {
          region: 'South East',
          percentageOfAcceptedQuotes: 30
        },
        {
          region: 'Wales',
          percentageOfAcceptedQuotes: 30
        }
      ]

      this.regionsData.columns = ['Region', '% of Accepted Quotes']

      data.forEach(d => {
        this.regionsData.rows.push([d.region, `${d.percentageOfAcceptedQuotes}%`])
      })
    },

    loadMarginChart() {
      let data = [
        {
          date: '2020-01-01T00:00:00+00:00',
          target: 0.09,
          actual: 0.2
        },
        {
          date: '2020-02-01T00:00:00+00:00',
          target: 0.09,
          actual: 0.01
        },
        {
          date: '2020-03-01T00:00:00+00:00',
          target: 0.09,
          actual: -0.02
        },
        {
          date: '2020-04-01T00:00:00+00:00',
          target: 0.09,
          actual: 0.08
        },
        {
          date: '2020-05-01T00:00:00+00:00',
          target: 0.09,
          actual: 0.01
        },
        {
          date: '2020-06-01T00:00:00+00:00',
          target: 0.09,
          actual: 0.08
        }
      ]

      let labels = data.map(d => this.$date(d.date).format('MMM YY'))

      let chartData = {
        type: 'line',
        options: {
          responsive: true
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Average Margin',
              ...this.config.colors.blue,
              data: data.map(d => d.actual),
              fill: false
            },
            {
              label: 'Target Margin',
              ...this.config.colors.red,
              data: data.map(d => d.target),
              fill: false
            }
          ]
        }
      }

      new Chart(this.$refs.averageMarginChart.getContext('2d'), chartData)
    },

    loadAcquisitionPieChart() {
      let data = [
        {
          source: 'On Platform',
          total: 1300
        },
        {
          source: 'Matchmaker',
          total: 20
        }
      ]

      let labels = data.map(d => d.source)

      let chartData = {
        type: 'pie',
        options: {
          responsive: true
        },
        data: {
          labels: labels,
          datasets: [
            {
              backgroundColor: [this.config.colors.blue.backgroundColor, this.config.colors.red.backgroundColor],
              data: data.map(d => d.total)
            }
          ]
        }
      }

      new Chart(this.$refs.acquisitionPieChart.getContext('2d'), chartData)
    }
  }
}
</script>
