<template>
  <UtilTableData
    :api="haulageApi"
    apiPath="/v2/crm/estimates"
    :columns="columns"
    dataPath="estimates"
    :initialSort="{ field: 'createdAt', type: 'desc' }"
    @on-row-click="$router.push(`/haulage/estimates/${$event.row.id}`)"
  />
</template>

<script>
import UtilTableData from '@/components/utils/UtilTableData'
import Api from '@/lib/api'

export default {
  name: 'HaulageEstimates',
  components: {
    UtilTableData
  },
  data() {
    return {
      haulageApi: new Api(),
      columns: [
        {
          label: 'Title',
          field: 'display'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Price',
          field: 'price',
          formatFn: value => this.$numberFormat(value, { style: 'currency', currency: 'GBP' })
        },
        {
          label: 'Created',
          field: 'createdAt',
          formatFn: value => this.$date.relative(value)
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
