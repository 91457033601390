<template>
  <UtilTableData
    :api="haulageApi"
    apiPath="/v2/crm/hauls"
    :columns="columns"
    dataPath="hauls"
    :initialSort="{ field: 'createdAt', type: 'desc' }"
    @on-row-click="openHaul($event.row.id)"
  />
</template>

<script>
import UtilTableData from '@/components/utils/UtilTableData'
import Api from '@/lib/api'

export default {
  name: 'HaulageHauls',
  components: {
    UtilTableData
  },
  data() {
    return {
      haulageApi: new Api(),
      columns: [
        {
          label: 'Title',
          field: 'display'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Created',
          field: 'createdAt',
          formatFn: value => this.$date.relative(value)
        }
      ]
    }
  },
  mounted() {
    if (this.$route.query.haul) {
      this.openHaul(this.$route.query.haul)
    }
  },
  methods: {
    openHaul(id) {
      this.$modal.open('ModalHaul', {
        id
      })
    }
  }
}
</script>
